export const environment = {
    //API: "https://localhost:7200/api",
    // API: "https://apijumalashop.helptk.shop/api",
    API: "https://apijumalafiltershop.helptk.shop/api",
    // API: "https://apijumlafeed.helptk.shop/api",
    // API: "https://apijumlaflowers.helptk.shop/",
    API_MASTER: "https://apimastertest.helptk.shop/api",
    DELIVERY_API: 'https://apidelivery.helptk.shop/api',
    API_KEY: "8e726f894cab777e606e9f597146b8c219714dc5",
    GEO_LOCATION_API: "https://ipapi.co/json/"
};
